import React from "react";
import AnimatedBlades from "../../assets/img/animatedBlades.png";
import BaseLogo from "../../assets/img/base.png";
import "./turbine-animation.css";

export default function TurbineAnimation({ overlayClassname }) {
  return (
    <div className={`animation-container ${overlayClassname ? overlayClassname : ""}`}>
      <div className="turbine-wrapper">
        <img
          src={AnimatedBlades}
          alt="Wind Turbine"
          className="rotating-turbine"
        />
        <img src={BaseLogo} alt="Turbine" className="base-turbine" />
      </div>
    </div>
  );
}
