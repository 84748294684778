import React, { useState, useMemo, useCallback } from "react";
import "./lightning-table.css";

export default function LightningTable({
  loading,
  items,
  fields,
  className,
  customSortFunction,
  customRenderFunction = null,
}) {
  const [sortByColumn, setSortByColumn] = useState(fields[0]?.key || "");
  const [ascDesc, setAscDesc] = useState(1);

  const sortedItems = useMemo(() => {
    const defaultSort = (a, b) => {
      if (a[sortByColumn] === b[sortByColumn]) return 0;
      if (a[sortByColumn] === null) return -1 * ascDesc;
      if (b[sortByColumn] === null) return ascDesc;

      // if text, sort alphabetically
      if (typeof a[sortByColumn] === "string")
        return a[sortByColumn].localeCompare(b[sortByColumn]) * ascDesc;

      if (a[sortByColumn] > b[sortByColumn]) {
        return -1 * ascDesc;
      }
      if (a[sortByColumn] < b[sortByColumn]) {
        return ascDesc;
      }

      return 0;
    };

    const sortFunction = customSortFunction || defaultSort;
    return [...items].sort(sortFunction);
  }, [items, customSortFunction, sortByColumn, ascDesc]);

  const clickedSort = useCallback(
    (headerName) => {
      if (headerName === sortByColumn) {
        if (ascDesc === -1) {
          setSortByColumn(null);
          setAscDesc(1);
        } else {
          setAscDesc(-ascDesc);
        }
      } else {
        setSortByColumn(headerName);
        setAscDesc(1);
      }
    },
    [sortByColumn, ascDesc]
  );

  return (
    <div className="lightning-table-wrapper">
      <div className={`lightning-table ${className}`}>
        <table>
          <thead>
            <tr>
              {fields.map(({ key, title }) => (
                <th key={key} onClick={() => clickedSort(key)}>
                <span className="title">{title}</span>
                <span className="arrows">
                  {sortByColumn === key || (key === "turbine_name" && sortByColumn === null) ? (
                    ascDesc === -1 ? "↓" : "↑"
                  ) : (
                    "↑↓"
                  )}
                </span>
              </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedItems.map((item, idx) => {
              if (customRenderFunction) return customRenderFunction(item, idx);

              return (
                <tr key={item.id || idx}>
                  {fields.map(({ key }) => (
                    <td key={key}>{item[key]}</td>
                  ))}
                </tr>
              );
            })}
            {!sortedItems.length && !loading && (
              <tr>
                <td colSpan={fields.length} style={{ textAlign: "center" }}>
                  No data matches your current selection
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
