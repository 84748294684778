import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Logout() {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpires");
        localStorage.removeItem("windfarms");
        localStorage.removeItem("windfarmsTimestamp");

        navigate("/login");
    }, [navigate]);

    return null;
}
