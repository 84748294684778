import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../../auth/user-service";
import { userAPI } from "../../api";
import WplInput from "../../components/wpl-input/WplInput";
import WplButton from "../../components/wpl-button/WplButton";
import { useKeyboardShortcut } from "../../components/UseKeyboardShortcut";
import TurbineAnimation from "../../components/turbine-animation/TurbineAnimation";
import WplPopup from "../../components/wpl-popup-window/WplPopup";
import logo from "../../assets/img/WPL-Global-Blade-Experts-04.png";
import "./login.css";

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgReset, setErrorMsgReset] = useState("");
  const [loading, setLoading] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const navigate = useNavigate();

  const doLogin = useCallback(() => {
    setLoading(true);
    userService
      .login(email, password)
      .then((r) => {
        setLoading(false);

        const redir_path = localStorage.getItem("redirAfterLogin");
        if (redir_path && redir_path !== "/login") {
          navigate(redir_path);
        } else {
          navigate("/");
        }
      })
      .catch((e) => {
        setLoading(false);
        setErrorMsg("Wrong password or username");
      });
  }, [email, password, navigate]);

  const createPasswordReset = useCallback(() => {
    setLoading(true);
    userAPI
      .requestPasswordReset(email)
      .then((r) => {
        setLoading(false);
        setResetPassword(false);
        setEmailSent(true);
      })
      .catch((e) => {
        setLoading(false);
        setErrorMsgReset("No user with that email found");
      });
  }, [email]);

  useKeyboardShortcut(["Enter"], doLogin);

  return (
    <main className="login-page">
      <div className="login-wrapper">
        <img
          alt="Wind Power Lab"
          src={logo}
          style={{
            height: 40,
            padding: 10,
            paddingBottom: 20,
            width: "auto",
          }}
        />

        <WplInput
          title={"Email"}
          type={"text"}
          value={email}
          onChanged={setEmail}
        />
        <WplInput
          title={"Password"}
          type={"password"}
          value={password}
          onChanged={setPassword}
        />
        <p className="error">{errorMsg}</p>
        <WplButton
          value={"Log in"}
          loading={loading}
          onClick={doLogin}
          disabled={!email || !password}
          big
        />
        <div className={`overlay ${loading ? "loading" : ""}`}>
          <TurbineAnimation overlayClassname="darker-overlay" />
        </div>

        <button
          onClick={(_) => setResetPassword(true)}
          className="forgot-pw-btn"
        >
          Forgot Password?
        </button>

        <div
          className={`password-reset-form ${
            emailSent ? "fade-out" : "fade-in"
          }`}
        >
          <WplPopup
            className="reset-pw-popup"
            showPopup={resetPassword}
            closePopup={setResetPassword}
          >
            <h1>Reset Password</h1>
            <p>
              Enter your email address and we will send you a link to reset your
              password.
            </p>
            <WplInput title="Email" value={email} onChanged={setEmail} />
            <p className="error">{errorMsgReset}</p>
            <WplButton
              value="Reset Password"
              big
              onClick={createPasswordReset}
            />
          </WplPopup>
        </div>

        <div
          className={`password-reset-success ${
            emailSent ? "fade-in" : "fade-out"
          }`}
        >
          <WplPopup
            className="reset-pw-popup"
            showPopup={emailSent}
            closePopup={setEmailSent}
          >
            <h1>Email Sent!</h1>
            <p>Please check your inbox for further instructions.</p>
          </WplPopup>
        </div>
      </div>
    </main>
  );
}
