import { userAPI } from "../api";

export const userService = {
    login,
    logout
}

function login(username, password) {
    return userAPI.login(username, password).then(setAuthToken)
}

export function logout(reasonUnauthorized = false) {
    const isLoginUrl = window.location.toString().toLowerCase().indexOf('/login') !== -1

    if (!isLoginUrl) {
        if (reasonUnauthorized) {
            localStorage.setItem('redirAfterLogin', '/')
        }
    }

    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('tokenExpires')
    localStorage.removeItem('windfarms')
    localStorage.removeItem('windfarmsTimestamp')

}


export function setAuthToken({ token, expires }) {
    localStorage.setItem('token', token)
    localStorage.setItem('tokenExpires', expires)
}
