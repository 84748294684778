import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import { LassieContext } from "../../../context/LassieProvider";
import { useNavigate, useParams } from "react-router-dom";
import { lassieDashboardAPI } from "../../../api";
import WplInput from "../../../components/wpl-input/WplInput";
import OlMap from "../../../components/ol-map/OlMap";
import TurbineLightningRuler from "../../../components/turbine-lightning-ruler/TurbineLightningRuler";
import AlertPopup from "../../../components/alert-popup/AlertPopup";
import LightningTable from "../lightning-table/LightningTable";
import { useSyncSelectedWindfarm } from "../../../hooks/useSyncSelectedWindfarm ";
import { toPrettyDateStr, toStandardWplTimestamp } from "../../../prettyDate";
import TurbineAnimation from "../../../components/turbine-animation/TurbineAnimation";
import "./historic-lightning-analysis.css";

export default function HistoricLightningAnalysis() {
  const navigate = useNavigate();
  const { windfarms, fetchTurbines, turbines } = useContext(LassieContext);
  const [showAllTurbines, setShowAllTurbines] = useState(false);
  const [selectedWindfarm, setSelectedWindfarm] = useState(null);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState({
    from: "",
    to: "",
  });
  const [historicAnalysis, setHistoricAnalysis] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [filters, setFilters] = useState({
    outside_certification: {
      filter: (tl) => true,
      value: "All",
    },
    distance: {
      filter: (tl) => tl.distance_km * 1000 <= 2500,
      value: 2500,
    },
    probability_of_hit: {
      filter: (tl) => tl.probability_of_hit * 100 >= 0,
      value: 0,
    },
    cloud_to_ground: {
      filter: (tl) => tl.cloud_to_ground,
      value: "cloud-to-ground",
    },
  });

  const turbineData = historicAnalysis !== null

  let { windfarm_id, turbine_id } = useParams();
  if (windfarm_id) {
    windfarm_id = parseInt(windfarm_id);
  }
  if (turbine_id) {
    turbine_id = parseInt(turbine_id);
  }

  const { from, to } = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    const from = params.get("from");
    const to = params.get("to");
    return {
      from: from || "",
      to: to || "",
    };
  }, [window.location.search]);

  const selectedTurbine = useMemo(() => {
    if (!turbines || !turbine_id) return null;
    return turbines.find((t) => t.id === turbine_id);
  }, [turbines, turbine_id]);

  const filteredLightningStrikes = useMemo(() => {
    if (!historicAnalysis) return [];
    return historicAnalysis.filter((tl) => {
      for (const [key, filter] of Object.entries(filters)) {
        if (!filter.filter(tl)) return false;
      }
      return true;
    });
  }, [historicAnalysis, filters]);

  const selectedTurbineWithLightning = useMemo(() => {
    return {
      ...selectedTurbine,
      turbine_lightning: filteredLightningStrikes,
    };
  }, [selectedTurbine, filteredLightningStrikes]);

  const isGetButtonDisabled = !(
    (selectedTimePeriod.from || from) &&
    (selectedTimePeriod.to || to) &&
    selectedWindfarm
  );

  const isExportButtonDisabled = !(
    selectedWindfarm && filteredLightningStrikes.length
  );

  const getData = async () => {
    if (!selectedWindfarm) return;

    const currentFrom = selectedTimePeriod.from || from;
    const currentTo = selectedTimePeriod.to || to;

    const windfarmPath = `/lassie/historic-lightning/windfarm/${selectedWindfarm.id}`;
    const turbinePath = selectedTurbine
      ? `/turbine/${selectedTurbine.id}`
      : "";
    navigate(`${windfarmPath}${turbinePath}?from=${currentFrom}&to=${currentTo}`);

    setError(false);
    setLoading(true);

    const from_timestamp = toStandardWplTimestamp(new Date(currentFrom));
    const to_timestamp = toStandardWplTimestamp(new Date(currentTo));

    try {
      const data = await lassieDashboardAPI.listHistoricLightningActivity({
        from_timestamp,
        to_timestamp,
        windfarm_id: selectedWindfarm.id,
        turbine_id: selectedTurbine ? selectedTurbine.id : null,
      });

      setShowAllTurbines(!selectedTurbine);
      setHistoricAnalysis(data);
    } catch (error) {
      setError(true);
      console.error("Error fetching historic lightning activity", error);
    } finally {
      setLoading(false);
    }
  };


  const handleTurbineChange = (name) => {
    setHistoricAnalysis(null);

    const currentFrom = selectedTimePeriod.from || from;
    const currentTo = selectedTimePeriod.to || to;

    if (name === "All turbines") {
      setShowAllTurbines(true);
      navigate(
        `/lassie/historic-lightning/windfarm/${selectedWindfarm.id}?from=${currentFrom}&to=${currentTo}`
      );
    } else {
      setShowAllTurbines(false);
      const t = turbines.find((t) => t.name === name);
      if (t) {
        navigate(
          `/lassie/historic-lightning/windfarm/${selectedWindfarm.id}/turbine/${t.id}?from=${currentFrom}&to=${currentTo}`
        );
      }
    }
  };

  const handleWindfarmChange = (name) => {
    setHistoricAnalysis(null);

    const currentFrom = selectedTimePeriod.from || from;
    const currentTo = selectedTimePeriod.to || to;

    const wf = windfarms.find((w) => w.name === name);
    if (wf) {
      navigate(
        `/lassie/historic-lightning/windfarm/${wf.id}}?from=${currentFrom}&to=${currentTo}`
      );
    }
  };

  const downloadLightningCSV = useCallback(() => {
    if (!selectedWindfarm) return;

    const lightningAnalysis = filteredLightningStrikes.map((s) => ({
      windfarm: selectedWindfarm.name,
      turbine_name: s.turbine_name,
      timestamp_local:
        toPrettyDateStr(new Date(s.timestamp), true) +
        "." +
        String(Math.round(s.nanosecond / 1e6)).padStart(3, "0"),
      peak_current: Math.round(s.peak_current * 10) / 10,
      distance_meters: Math.round(s.distance_km * 100) * 10,
      latitude: s.lat,
      longitude: s.lon,
      semi_major_meters: Math.round(s.semi_major_axis * 100) * 10,
      semi_minor_meters: Math.round(s.semi_minor_axis * 100) * 10,
      probability_within_collection_area: Math.round(
        s.probability_of_hit * 100
      ),
      outside_iec_certification: Boolean(s.outside_certification),
      is_subsequent: Boolean(s.parent_lightning_id !== null),
      cloud_to_ground: Boolean(s.cloud_to_ground),
    }));

    const csvData =
      "data:text/csv;charset=utf-8," +
      Object.keys(lightningAnalysis[0]).join(",") +
      "\n" +
      lightningAnalysis.map((row) => Object.values(row).join(",")).join("\n");

    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvData));
    showAllTurbines
      ? link.setAttribute("download", `lassie-${selectedWindfarm.name}.csv`)
      : link.setAttribute(
        "download",
        `lassie-${selectedWindfarm.name}-${selectedTurbine.name}.csv`
      );

    document.body.appendChild(link);
    link.click();
  }, [
    selectedTurbine,
    filteredLightningStrikes,
    selectedWindfarm,
    showAllTurbines,
  ]);

  useEffect(() => {
    setSelectedTimePeriod({ from: from, to: to });
  }, [from, to]);

  useEffect(() => {
    if (!windfarm_id || !windfarms) return;
    if (selectedWindfarm && selectedWindfarm.id === windfarm_id) return;

    setSelectedWindfarm(windfarms.find((wf) => wf.id === windfarm_id));
    fetchTurbines(windfarm_id);
  }, [selectedWindfarm, windfarm_id, windfarms]);

  useSyncSelectedWindfarm(windfarm_id);

  return (
    <div className="historic-lightning-analysis">
      <AlertPopup
        showPopup={error}
        closePopup={setError}
        status="wrong"
        title={"Something went wrong..."}
        buttonText={"Close"}
      >
        <p>
          The requested data is too large to process. Please try again with a
          shorter date range.
        </p>
      </AlertPopup>

      <h1>Historic Lightning Analysis</h1>
      <div className="form-container">
        <div className="select-wrapper">
          <h5 className="label">Select a time period</h5>
          <div className="period-selector">
            <input
              type="date"
              value={selectedTimePeriod.from || from}
              onChange={(e) =>
                setSelectedTimePeriod({
                  ...selectedTimePeriod,
                  from: e.target.value,
                })
              }
            />
            <input
              type="date"
              value={selectedTimePeriod.to || to}
              onChange={(e) =>
                setSelectedTimePeriod({
                  ...selectedTimePeriod,
                  to: e.target.value,
                })
              }
            />
          </div>

          <h5 className="label">Select a windfarm</h5>
          <WplInput
            className="no-risk-windfarms"
            options={windfarms.map((wf) => wf.name)}
            onlySelectableOptions
            value={selectedWindfarm && selectedWindfarm.name}
            onChanged={(name) => handleWindfarmChange(name)}
          />

          <h5 className="label">Select a turbine</h5>
          <WplInput
            className="no-risk-windfarms"
            options={[
              "All turbines",
              ...turbines.map((t) => t.name).sort((a, b) => a.localeCompare(b)),
            ]}
            onlySelectableOptions
            value={selectedTurbine ? selectedTurbine.name : "All turbines"}
            onChanged={(e) => handleTurbineChange(e)}
          />
        </div>

        <div className="button-area">
          <button
            className={`get-btn ${isGetButtonDisabled ? "disabled" : ""}`}
            onClick={() => getData()}
          >
            Get lightning data
          </button>

          <button
            value="Export lightning to .csv"
            className={`export-btn ${isExportButtonDisabled ? "disabled" : ""}`}
            onClick={downloadLightningCSV}
          >
            Download as .csv
          </button>
        </div>
      </div>

      {loading && (
        <div className="historic-loading">
          <TurbineAnimation />
        </div>
      )}

      <div className="windfarm-report">
        {selectedWindfarm && turbineData && (
          <div className="notification-turbine">
            {selectedWindfarm && (
              <h2>
                {selectedWindfarm.name}
                {selectedTurbine ? ` - ${selectedTurbine.name}` : ""}
              </h2>
            )}

            {turbineData &&
              selectedTurbine &&
              filteredLightningStrikes && (
                <TurbineLightningRuler strikes={filteredLightningStrikes} />
              )}

            {turbineData && (
              <div className="table-wrapper">
                <div className="filters" style={{ display: "flex" }}>
                  <WplInput
                    title="In-/outside certification"
                    options={[
                      "All",
                      "Inside certification",
                      "Outside certification",
                    ]}
                    onlySelectableOptions
                    value={
                      (filters.outside_certification &&
                        filters.outside_certification.value) ||
                      "All"
                    }
                    onChanged={(value) => {
                      let newFilter;
                      if (value === "All") newFilter = () => true;
                      if (value === "Inside certification")
                        newFilter = (tl) => !tl.outside_certification;
                      if (value === "Outside certification")
                        newFilter = (tl) => tl.outside_certification;
                      setFilters((currentFilterValues) => ({
                        ...currentFilterValues,
                        outside_certification: {
                          filter: newFilter,
                          value,
                        },
                      }));
                    }}
                  />
                  <WplInput
                    title="Distance (m)"
                    value={filters.distance ? filters.distance.value : 2500}
                    onChanged={(value) => {
                      setFilters((p) => ({
                        ...p,
                        distance: {
                          filter: (tl) =>
                            Math.round(tl.distance_km * 100) * 10 <= value,
                          value,
                        },
                      }));
                    }}
                  />
                  <WplInput
                    title="Min probability of hit (%)"
                    value={
                      filters.probability_of_hit
                        ? filters.probability_of_hit.value
                        : 0
                    }
                    onChanged={(value) => {
                      setFilters((p) => ({
                        ...p,
                        probability_of_hit: {
                          filter: (tl) => tl.probability_of_hit * 100 >= value,
                          value,
                        },
                      }));
                    }}
                  />
                  <WplInput
                    title="Lightning type"
                    options={["All", "cloud-to-ground", "intra-cloud"]}
                    onlySelectableOptions
                    value={
                      filters.cloud_to_ground
                        ? filters.cloud_to_ground.value
                        : "cloud-to-ground"
                    }
                    onChanged={(value) => {
                      let newFilter;
                      if (value === "All") newFilter = () => true;
                      if (value === "cloud-to-ground")
                        newFilter = (tl) => tl.cloud_to_ground;
                      if (value === "intra-cloud")
                        newFilter = (tl) => !tl.cloud_to_ground;
                      setFilters((currentFilterValues) => ({
                        ...currentFilterValues,
                        cloud_to_ground: {
                          filter: newFilter,
                          value,
                        },
                      }));
                    }}
                  />
                </div>
                <div className="historic-table-scroll">
                  <LightningTable
                    loading={loading}
                    fields={[
                      {
                        key: "turbine_name",
                        title: "Turbine",
                      },
                      {
                        key: "distance_km",
                        title: "Distance",
                      },
                      {
                        key: "peak_current",
                        title: "Peak current",
                      },
                      {
                        key: "probability_of_hit",
                        title: "Probability",
                      },
                      {
                        key: "outside_certification",
                        title: "Outside certification",
                      },
                      {
                        key: "parent_lightning_id",
                        title: "Subsequent stroke",
                      },
                      {
                        key: "semi_major_axis",
                        title: "Major axis",
                      },
                      {
                        key: "semi_minor_axis",
                        title: "Minor axis",
                      },
                      {
                        key: "timestamp",
                        title: "Timestamp (Local Time)",
                      },
                    ]}
                    items={filteredLightningStrikes}
                    className="lightning-table"
                    customRenderFunction={(tl, i) => (
                      <tr
                        className={`lightning-row ${tl.outside_certification ? "outside-cert" : ""
                          }`}
                        key={i}
                      >
                        <td>{tl.turbine_name}</td>
                        <td>{Math.round(tl.distance_km * 100) * 10} m</td>
                        <td>{Math.round(tl.peak_current * 10) / 10} kA</td>
                        <td>{Math.round(tl.probability_of_hit * 100)}%</td>
                        <td>{tl.outside_certification ? "✔" : ""}</td>
                        <td>{tl.parent_lightning_id !== null ? "✔" : " "}</td>
                        <td>{tl.semi_major_axis * 1000} m</td>
                        <td>{tl.semi_minor_axis * 1000} m</td>

                        <td>
                          {toPrettyDateStr(new Date(tl.timestamp), true, true)}
                        </td>
                      </tr>
                    )}
                  />
                </div>
              </div>
            )}
            {turbineData &&
              !showAllTurbines &&
              selectedTurbineWithLightning.collection_area && (
                <OlMap
                  lightning_strikes={filteredLightningStrikes}
                  turbines={[selectedTurbineWithLightning]}
                />
              )}
          </div>
        )}
      </div>
    </div>
  );
}