import React from "react";
import WdProvider from "../../context/WdProvider";
import MainPage from "../main/MainPage";

const WdWrapper = () => {
  return (
    <WdProvider>
      <MainPage />
    </WdProvider>
  );
};

export default WdWrapper;
