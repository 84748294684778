import React, { useCallback, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { LassieContext } from "../../../context/LassieProvider";
import LightningStrikeList from "../LightningStrikeList";
import OlMap from "../../../components/ol-map/OlMap";
import RiskTimelineGraph from "../../lightning/lightning-windfarm/RiskTimelineGraph";
import {
  lassieDashboardAPI,
  lightningUserActionAPI,
  lightningAPI,
} from "../../../api";
import { toStandardWplTimestamp } from "../../../prettyDate";
import handledIcon from "../../../assets/img/handled-icon-color.png";
import markedForInspIcon from "../../../assets/img/mark-for-inspection-color.png";
import deleteIcon from "../../../assets/img/delete-icon.png";
import deleteIconColor from "../../../assets/img/delete-icon-color.png";
import "./lightning-turbine-page.css";

export default function LightningTurbinePage() {
  const { fetchTurbines, turbines } = useContext(LassieContext);
  const [historicUserActions, setHistoricUserActions] = useState([]);
  const [riskTimeline, setRiskTimeline] = useState(null);

  let { turbine_id } = useParams();
  turbine_id = parseInt(turbine_id);

  let { windfarm_id } = useParams();
  if (windfarm_id) {
    windfarm_id = parseInt(windfarm_id);
  }

  const turbine = turbines.length > 0 ? turbines[0] : null;

  const fetchUserActions = useCallback(() => {
    const today = new Date();
    const twoYearsAgo = new Date(today.setDate(today.getDate() - 720));
    lassieDashboardAPI
      .listTurbineHistoricUserAction(
        turbine_id,
        toStandardWplTimestamp(twoYearsAgo),
        toStandardWplTimestamp(new Date())
      )
      .then(setHistoricUserActions);
  }, [turbine_id]);

  useEffect(() => {
    fetchTurbines(null, turbine_id);
    fetchUserActions();
  }, [fetchUserActions, turbine_id]);

  useEffect(() => {
    lightningAPI.listWindfarmRisktimeline(windfarm_id).then(setRiskTimeline);
  }, []);

  const getIcon = (action) => {
    if (!action) return null;

    if (action === "marked_for_inspection" || action === "inspection") {
      return markedForInspIcon;
    } else if (action === "acceptable_risk") {
      return handledIcon;
    } else {
      return null;
    }
  };

  const formatAction = (action) => {
    const formatted = action.replace(/_/g, " ");
    return formatted.charAt(0).toUpperCase() + formatted.slice(1);
  };

  if (!turbine) return null;

  return (
    <div className="lightning-turbine-page">
      <h1>{turbine.name}</h1>
      <OlMap
        turbines={[turbine]}
        lightning_strikes={turbine.turbine_lightning}
      />

      <h2>Actions</h2>
      <table className="user-action-table">
        <thead>
          <tr>
            <th></th>
            <th>Action</th>
            <th>Target date</th>
            <th>Timestamp created</th>
            <th>Username</th>
            <th>Comment</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {historicUserActions.map((action) => (
            <tr key={action.id}>
              <td>
                {action && (
                  <img src={getIcon(action.action)} className="action-icon" alt='Action icon' />
                )}
              </td>
              <td>{formatAction(action.action)}</td>
              <td>{action.target_date}</td>
              <td>{action.timestamp_created}</td>
              <td>{action.user_name}</td>
              <td>
                <div className="comment-wrapper">{action.user_comment}</div>
              </td>
              {turbine.latest_user_action &&
              turbine.latest_user_action.id === action.id ? (
                <td>
                  <button
                    className="delete-btn"
                    onClick={(_) =>
                      lightningUserActionAPI
                        .delete_user_action(action.id)
                        .then((_) => {
                          fetchTurbines(null, turbine_id);
                          fetchUserActions();
                        })
                    }
                  >
                    Delete{" "}
                    <img
                      src={deleteIconColor}
                      alt="Delete user action"
                      className="action-icon default-icon"
                    />
                    <img
                      src={deleteIcon}
                      alt="Delete user action"
                      className="action-icon hover-icon"
                    />
                  </button>
                </td>
              ) : (
                <td></td>
              )}
            </tr>
          ))}
          {historicUserActions.length === 0 && (
            <tr>
              <td colSpan={6}>No registered user actions for this turbine.</td>
            </tr>
          )}
        </tbody>
      </table>

      {turbine.turbine_lightning.length > 0 && (
        <>
          <h2>Lightning activity</h2>
          <LightningStrikeList lightningStrikes={turbine.turbine_lightning} />
        </>
      )}
      <RiskTimelineGraph riskTimeline={riskTimeline} turbine={turbine} />
    </div>
  );
}
