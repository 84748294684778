import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from "react";
import { useParams } from "react-router-dom";
import { LassieContext } from "../../../context/LassieProvider";
import WplInput from "../../../components/wpl-input/WplInput";
import { toPrettyDateStr } from "../../../prettyDate";
import {
  icons_at_risk,
  icons_handled,
  icons_marked_for_inspection,
} from "../../../components/ol-map/icons";
import WplSwitch from "../../../components/wpl-switch/wplSwitch";
import WplFileUpload from "../../../components/wpl-file-upload/WplFileUpload";
import TurbineManagerPopup from "../../../components/turbine-namager-popup/TurbineManagerPopup";
import TurbineManagerTable from "../../../components/turbine-manager-table/TurbineMagerTable";
import AdvanceFilters from "../../../components/advance-filters/AdvanceFilters";
import { getIconForTurbine } from "../../../components/ol-map/icons";
import magnifierIcon from "../../../assets/img/magnifier.png";
import closeIcon from "../../../assets/img/cancel.png";
import closeIconW from "../../../assets/img/close-w.png";
import turbineIcon from "../../../assets/img/windfarm.png";
import addCommentIcon from "../../../assets/img/message.png";
import calendarIcon from "../../../assets/img/calendar.png";
import handledIcon from "../../../assets/img/handled-icon.png";
import handledIconColor from "../../../assets/img/handled-icon-color.png";
import markForInspectionIcon from "../../../assets/img/mark-for-inspection-icon.png";
import markForInspectionIconColor from "../../../assets/img/mark-for-inspection-color.png";
import WPLAnimatedInput from "../../../components/wpl-animated-input/WplAnimatedInput";
import "./turbine-manager.css";

export default function TurbineManager() {
  const { fetchTurbines, turbines, createUserAction } =
    useContext(LassieContext);
  const [seeMoreTurbineIds, setSeeMoreTurbineIds] = useState([]);
  const [showUserActions, setShowUserActions] = useState(false);
  const [userComment, setUserComment] = useState("");
  const [targetDate, setTargetDate] = useState(toPrettyDateStr(new Date()));
  const [selectedTurbineIds, setSelectedTurbineIds] = useState([]);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filterOnPeak, setFilterOnPeak] = useState(true);
  const [onlyMostProbable, setOnlyMostProbable] = useState(false);
  const [onlyWithoutComment, setOnlyWithoutComment] = useState(false);
  const [peakCurrentRange, setPeakCurrentRange] = useState([-999, 999]);
  const [modelFilter, setModelFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState([]);
  const [proximityFilter, setProximityFilter] = useState(2000);
  const [probabilityFilter, setProbabilityFilter] = useState(null);
  const [showOutsideIEC, setShowOutsideIEC] = useState(false);
  const [sortField, setSortField] = useState("probability");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [isFiltersActive, setIsFilterActive] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState({});

  const datePickerRef = useRef();
  const openDatePicker = useCallback(() => {
    if (!datePickerRef.current) return;
    datePickerRef.current.showPicker();
  }, [datePickerRef]);

  let { windfarm_id } = useParams();
  if (windfarm_id) {
    windfarm_id = parseInt(windfarm_id);
  }

  useEffect(() => {
    if (!windfarm_id) return;
    fetchTurbines(windfarm_id);
  }, [windfarm_id]);

  const uploadedFile = useCallback(
    (filename, data) => {
      setUploadedDocuments((p) => ({ ...p, [filename]: data }));
    },
    [setUploadedDocuments]
  );

  const sortTurbines = useCallback(
    (a, b) => {
      let result = 0;
      switch (sortField) {
        case "name":
          const alphanumericCompare = (str1, str2) => {
            const regex = /(\d+)|(\D+)/g;
            const chunks1 = str1.match(regex);
            const chunks2 = str2.match(regex);

            for (let i = 0; i < Math.min(chunks1.length, chunks2.length); i++) {
              const chunk1 = chunks1[i];
              const chunk2 = chunks2[i];

              if (!isNaN(chunk1) && !isNaN(chunk2)) {
                const num1 = parseInt(chunk1, 10);
                const num2 = parseInt(chunk2, 10);
                if (num1 !== num2) {
                  return num1 - num2;
                }
              } else if (chunk1 !== chunk2) {
                return chunk1.localeCompare(chunk2);
              }
            }

            return chunks1.length - chunks2.length;
          };

          result = alphanumericCompare(a.name, b.name);
          break;

        case "distance":
          const aDistance = Math.min(
            ...a.turbine_lightning.map((l) => l.distance_km)
          );
          const bDistance = Math.min(
            ...b.turbine_lightning.map((l) => l.distance_km)
          );
          result = aDistance - bDistance;
          break;

        case "probability":
        default:
          const probDiff =
            b.probability_of_atleast_one - a.probability_of_atleast_one;

          if (
            Math.round(b.probability_of_atleast_one * 100) ===
            Math.round(a.probability_of_atleast_one * 100)
          ) {
            result =
              Math.max(
                ...b.turbine_lightning.map((tl) => tl.probability_of_hit)
              ) -
              Math.max(
                ...a.turbine_lightning.map((tl) => tl.probability_of_hit)
              );
          } else {
            result = probDiff;
          }
          break;
      }

      return sortOrder === "ASC" ? result : -result;
    },
    [sortField, sortOrder]
  );

  const filteredTurbines = useMemo(() => {
    if (!turbines) return [];
    let res = [...turbines];

    res = res
      .filter(
        (t) =>
          !searchText ||
          t.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
      )
      .filter((t) => !statusFilter.length || statusFilter.includes(t.status))
      .filter(
        (t) =>
          !showOutsideIEC ||
          t.turbine_lightning.some((tl) => tl.outside_certification)
      )
      .filter((t) => {
        if (isFiltersActive) {
          return (
            !modelFilter ||
            modelFilter === "All Models" ||
            t.model === modelFilter
          );
        }
        return true;
      })
      .filter((t) => {
        if (isFiltersActive) {
          return (
            probabilityFilter === null ||
            Math.round(t.probability_of_atleast_one * 100) >= probabilityFilter
          );
        }
        return true;
      });

    res = res
      .map((t) => {
        const filtered_lightning = t.turbine_lightning
          .filter((l) => !onlyMostProbable || l.most_probable)
          .filter((l) => {
            if (isFiltersActive) {
              return (
                l.peak_current > peakCurrentRange[0] &&
                l.peak_current < peakCurrentRange[1]
              );
            }
            return true;
          })
          .filter((l) => {
            if (isFiltersActive) {
              const distanceInMeters = l.distance_km * 1000;
              return distanceInMeters < proximityFilter;
            }
            return true;
          })
          .filter((l) => {
            if (isFiltersActive) {
              return (
                probabilityFilter === null ||
                Math.round(l.probability_of_hit * 100) >= probabilityFilter
              );
            }
            return true;
          })
          .filter((l) => !showOutsideIEC || l.outside_certification);

        if (
          filtered_lightning.length === 0 &&
          (isFiltersActive || onlyMostProbable)
        ) {
          return null;
        }

        const prob =
          1 -
          filtered_lightning
            .map((tl) => 1 - tl.probability_of_hit)
            .reduce((p, c) => p * c, 1);

        return {
          ...t,
          turbine_lightning: filtered_lightning,
          probability_of_atleast_one: prob,
        };
      })
      .filter((t) => t !== null);

    return res;
  }, [
    turbines,
    modelFilter,
    onlyWithoutComment,
    statusFilter,
    probabilityFilter,
    showOutsideIEC,
    proximityFilter,
    filterOnPeak,
    onlyMostProbable,
    peakCurrentRange,
    isFiltersActive,
    searchText,
  ]);

  const validSelectedTurbineIds = useMemo(() => {
    const filteredIds = filteredTurbines.map((t) => t.id);
    return selectedTurbineIds.filter((id) => filteredIds.includes(id));
  }, [filteredTurbines, selectedTurbineIds]);

  const modelOptions = useMemo(() => {
    const models = turbines.map((t) => t.model);
    const uniqueModels = models.filter((m, idx) => models.indexOf(m) === idx);
    return ["All Models", ...uniqueModels];
  }, [turbines]);

  const downloadWorkOrderCSV = useCallback(() => {
    const exportTurbines = filteredTurbines
      .filter((t) => selectedTurbineIds.indexOf(t.id) >= 0)
      .map((t) => ({
        name: t.name,
        windfarm: t.windfarm,
        model: t.model,
        user_comment: t.user_comment,
        Probability: Math.round(t.probability_of_atleast_one * 100),
        distance_meters: Math.round(
          Math.min(
            ...t.turbine_lightning.map((l) => parseFloat(l.distance_km))
          ) * 1000
        ),
        iec_certification: t.turbine_lightning.some(
          (tl) => tl.outside_certification
        )
          ? "Outside certification"
          : "",
      }));

    const csvData =
      "data:text/csv;charset=utf-8," +
      Object.keys(exportTurbines[0]).join(",") +
      "\n" +
      exportTurbines.map((row) => Object.values(row).join(",")).join("\n");

    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvData));
    link.setAttribute(
      "download",
      `${toPrettyDateStr(new Date())}-lassie-work-order.csv`
    );
    document.body.appendChild(link);
    link.click();
  }, [filteredTurbines, selectedTurbineIds]);

  const handleMinChange = (e) => {
    const newMin = e.target.value;
    if (newMin === "" || !isNaN(newMin)) {
      setPeakCurrentRange((prevRange) => [
        newMin === "" ? "" : Number(newMin),
        prevRange[1],
      ]);
    }
  };

  useEffect(() => {
    if (validSelectedTurbineIds.length !== selectedTurbineIds.length) {
      setSelectedTurbineIds(validSelectedTurbineIds);
    }
  }, [validSelectedTurbineIds, selectedTurbineIds]);

  const handleMaxChange = (e) => {
    const newMax = e.target.value;
    if (newMax === "" || !isNaN(newMax)) {
      setPeakCurrentRange((prevRange) => [
        prevRange[0],
        newMax === "" ? "" : Number(newMax),
      ]);
    }
  };

  const handleDistanceChange = (e) => {
    const newDistance = e.target.value;
    if (newDistance === "" || !isNaN(newDistance)) {
      setProximityFilter(newDistance === "" ? "" : Number(newDistance));
    }
  };

  const handleProbabilityChange = (e) => {
    const newProbability = e.target.value;
    if (newProbability === "" || !isNaN(newProbability)) {
      setProbabilityFilter(newProbability === "" ? "" : Number(newProbability));
    }
  };

  const handleCreateUserAction = ({
    turbine_ids,
    action,
    target_date,
    user_comment,
    file_names,
    file_datas,
  }) => {
    createUserAction({
      turbine_ids: selectedTurbineIds,
      action: action,
      target_date: targetDate,
      user_comment: userComment,
      file_names: Object.keys(uploadedDocuments),
      file_datas: Object.keys(uploadedDocuments).map(
        (k) => uploadedDocuments[k]
      ),
    });
    setUserComment("");
    setSelectedTurbineIds([]);
  };

  const removeTurbine = (id) => {
    setSelectedTurbineIds((prevSelectedIds) =>
      prevSelectedIds.filter((turbineId) => turbineId !== id)
    );
  };

  const toggleStatusFilter = (status) => {
    setStatusFilter((prevFilter) =>
      prevFilter.includes(status)
        ? prevFilter.filter((s) => s !== status)
        : [...prevFilter, status]
    );
  };

  const renderManagedTurbines = (t, content, className) => {
    return t.turbine_lightning.length !== 0 ? (
      <td className={className}>{content}</td>
    ) : (
      <td>N/A</td>
    );
  };

  const handleRowCheckboxChange = (e, turbineId, index) => {
    const isShiftPressed = e.nativeEvent.shiftKey;
    const isSelected = selectedTurbineIds.includes(turbineId);

    if (isShiftPressed && lastSelectedIndex !== null) {
      const start = Math.min(lastSelectedIndex, index);
      const end = Math.max(lastSelectedIndex, index);
      const range = filteredTurbines.slice(start, end + 1).map((t) => t.id);

      if (isSelected) {
        setSelectedTurbineIds((prev) =>
          prev.filter((id) => !range.includes(id))
        );
      } else {
        setSelectedTurbineIds((prev) =>
          Array.from(new Set([...prev, ...range]))
        );
      }
    } else {
      if (isSelected) {
        setSelectedTurbineIds((prev) => prev.filter((id) => id !== turbineId));
      } else {
        setSelectedTurbineIds((prev) => [...prev, turbineId]);
      }
    }

    setLastSelectedIndex(index);
  };

  const handleHeaderCheckboxChange = () => {
    if (selectedTurbineIds.length === filteredTurbines.length) {
      setSelectedTurbineIds([]);
    } else {
      setSelectedTurbineIds(filteredTurbines.map((t) => t.id));
    }
  };

  const resetFilters = () => {
    setFilterOnPeak(true);
    setOnlyMostProbable(false);
    setOnlyWithoutComment(false);
    setPeakCurrentRange([-999, 999]);
    setModelFilter(null);
    setStatusFilter([]);
    setProximityFilter(2000);
    setProbabilityFilter(null);
    setShowOutsideIEC(false);
  };

  const submitUserAction = (action) => {
    handleCreateUserAction({
      turbine_ids: selectedTurbineIds,
      action: action,
      target_date: targetDate,
      user_comment: userComment,
      file_names: Object.keys(uploadedDocuments),
      file_datas: Object.keys(uploadedDocuments).map(
        (k) => uploadedDocuments[k]
      ),
    });

    setShowUserActions(false);
  };

  const toggleSort = (field) => {
    if (sortField === field) {
      setSortOrder((prevOrder) => (prevOrder === "ASC" ? "DESC" : "ASC"));
    } else {
      setSortField(field);
      setSortOrder("ASC");
    }
  };

  return (
    <div className="turbine-manager">
      {showUserActions && (
        <TurbineManagerPopup
          showPopup={showUserActions}
          closePopup={setShowUserActions}
        >
          <div className="actions-input-wrapper">
            <span className="flex-center">
              <img
                src={calendarIcon}
                className="turbine-icon"
                alt="Turbine icon"
              />
              <p className="label required-field"> Choose event date</p>
              <WplInput
                inputRef={datePickerRef}
                onClick={openDatePicker}
                className="performed-inspection-date"
                type="date"
                value={targetDate}
                onChanged={(e) => setTargetDate(e)}
              />
            </span>
            <div className="selected-turbines-popup">
              {filteredTurbines
                .filter((t) => selectedTurbineIds.indexOf(t.id) >= 0)
                .map((t, index, arr) => (
                  <div
                    key={t.id}
                    className="turbine-name-badge"
                    onClick={() => removeTurbine(t.id)}
                  >
                    <p className="turbine-name">{t.name}</p>
                    <img
                      src={closeIconW}
                      className="delete-badge-icon"
                      alt="close icon"
                    />
                  </div>
                ))}
            </div>

            <div className="attachments-wrapper">
              <div className="comment-area-wrapper">
                <span className="flex-center cursor-pointer action-popup-label">
                  <img
                    src={addCommentIcon}
                    className="turbine-icon"
                    alt="turbine icon"
                  />
                  <p className="label">Add a comment</p>
                </span>
                <textarea
                  className="comment-area"
                  value={userComment}
                  onChange={(e) => setUserComment(e.target.value)}
                />
              </div>

              <div className="upload-wrapper">
                <WplFileUpload
                  text="Upload inspection-related documents (optional)"
                  asPopup={false}
                  displayFiles={Object.keys(uploadedDocuments).length > 0}
                  didSelectFile={uploadedFile}
                  fullWidth={true}
                  disabled={selectedTurbineIds.length > 1}
                />
              </div>
            </div>
          </div>

          <div className="btn-container">
            <button
              className="action-btn mark-for-inspection-btn"
              disabled={!targetDate || selectedTurbineIds.length === 0}
              onClick={() => submitUserAction("marked_for_inspection")}
            >
              Mark for inspection
              <img
                src={markForInspectionIcon}
                alt="Marked for inspection"
                className="action-icon default-icon"
              />
              <img
                src={markForInspectionIconColor}
                alt="Marked for inspection"
                className="action-icon hover-icon"
              />
            </button>
            <button
              className="action-btn accept-risk-btn"
              disabled={!targetDate || selectedTurbineIds.length === 0}
              onClick={() => submitUserAction("acceptable_risk")}
            >
              Accept risk
              <img
                src={handledIcon}
                alt="Accept risk"
                className="action-icon default-icon"
              />
              <img
                src={handledIconColor}
                alt="Accept risk"
                className="action-icon hover-icon"
              />
            </button>
          </div>
        </TurbineManagerPopup>
      )}
      <div className="filter-menu">
        <div className="turbine-status-container">
          <div className="search-bar-container">
            <div className="search-bar-wrapper">
              <WPLAnimatedInput
                label="Search turbines..."
                type="text"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                inputClassName="search-input"
                containerClassName="search-input-container"
              />
              <img src={magnifierIcon} className="search-icon" alt="Search" />
            </div>
            <div className="count-display">
              <p className="label">
                <img
                  src={turbineIcon}
                  className="turbine-icon"
                  alt="turbine icon"
                />
                {filteredTurbines.length}/{turbines.length}
              </p>
            </div>
          </div>
          <h4 className="label">Turbine status</h4>
          <div className="status-btn-container">
            <div
              className={`status-btn ${
                statusFilter.includes("at_risk") ? "status-active" : ""
              }`}
              onClick={() => toggleStatusFilter("at_risk")}
            >
              <div className="button__content">
                <img
                  src={icons_at_risk.High}
                  alt="At risk"
                  title="At risk"
                  className="status-icon"
                />
              </div>
            </div>

            <div
              className={`status-btn ${
                statusFilter.includes("marked_for_inspection")
                  ? "status-active"
                  : ""
              }`}
              onClick={() => toggleStatusFilter("marked_for_inspection")}
            >
              <div className="button__content">
                <img
                  src={icons_marked_for_inspection}
                  alt="Marked for inspection"
                  title="Marked for inspection"
                  className="status-icon"
                />
              </div>
            </div>

            <div
              className={`status-btn ${
                statusFilter.includes("acceptable_risk") ? "status-active" : ""
              }`}
              onClick={() => toggleStatusFilter("acceptable_risk")}
            >
              <div className="button__content">
                <img
                  src={icons_handled}
                  alt="Acceptable risk"
                  title="Acceptable risk"
                  className="status-icon"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="probable-strikes-container">
          <h4 className="label">Only show outside IEC</h4>
          <span className="switch-container">
            <WplSwitch
              value={showOutsideIEC}
              onChanged={() => setShowOutsideIEC(!showOutsideIEC)}
              tooltip="Read more about IEC certification in the FAQ"
            />
          </span>

          <h4 className="label">Probable strikes only </h4>

          <span className="switch-container">
            <WplSwitch
              value={onlyMostProbable}
              onChanged={() => setOnlyMostProbable(!onlyMostProbable)}
            />
          </span>
          <h4 className="label">Advance filters </h4>
          <span className="switch-container">
            <WplSwitch
              value={isFiltersActive}
              onChanged={() => setIsFilterActive(!isFiltersActive)}
            />
          </span>
        </div>

        <div className="advance-filters-container">
          {isFiltersActive && (
            <AdvanceFilters
              showAdvanceFilter={isFiltersActive}
              modelFilter={modelFilter}
              setModelFilter={setModelFilter}
              resetFilters={resetFilters}
              modelOptions={modelOptions}
              showOutsideIEC={showOutsideIEC}
              setShowOutsideIEC={setShowOutsideIEC}
              onlyMostProbable={onlyMostProbable}
              setOnlyMostProbable={setOnlyMostProbable}
              peakCurrentRange={peakCurrentRange}
              handleMinChange={handleMinChange}
              handleMaxChange={handleMaxChange}
              proximityFilter={proximityFilter}
              handleDistanceChange={handleDistanceChange}
              probabilityFilter={probabilityFilter}
              handleProbabilityChange={handleProbabilityChange}
            />
          )}
        </div>

        <div>
          <h4 className="label">Selected turbines</h4>
          <div className="flex-center">
            <p className="label">
              {
                filteredTurbines.filter((t) =>
                  selectedTurbineIds.includes(t.id)
                ).length
              }{" "}
              selected
            </p>
            <div
              className="clear-badge"
              onClick={() => setSelectedTurbineIds([])}
            >
              <p className="turbine-name">Clear selection</p>
              <img src={closeIcon} className="clear-icon" alt="close icon" />
            </div>
          </div>
        </div>
        <div className="selected-turbines-container">
          {filteredTurbines
            .filter((t) => selectedTurbineIds.indexOf(t.id) >= 0)
            .map((t, index, arr) => (
              <div
                key={t.id}
                className="turbine-name-badge"
                onClick={() => removeTurbine(t.id)}
              >
                <p className="turbine-name">{t.name}</p>
                <img
                  src={closeIconW}
                  className="delete-badge-icon"
                  alt="close icon"
                />
              </div>
            ))}
        </div>

        <div className="btn-container">
          <button
            className="sidebar-btn"
            onClick={() => setShowUserActions(true)}
            disabled={selectedTurbineIds.length === 0}
          >
            Add comment
          </button>
          <button
            className="sidebar-btn"
            onClick={downloadWorkOrderCSV}
            disabled={selectedTurbineIds.length === 0}
          >
            Export
          </button>
        </div>
      </div>

      <TurbineManagerTable
        filteredTurbines={filteredTurbines}
        selectedTurbineIds={selectedTurbineIds}
        handleHeaderCheckboxChange={handleHeaderCheckboxChange}
        handleRowCheckboxChange={handleRowCheckboxChange}
        toggleSort={toggleSort}
        sortField={sortField}
        sortOrder={sortOrder}
        sortTurbines={sortTurbines}
        seeMoreTurbineIds={seeMoreTurbineIds}
        setSeeMoreTurbineIds={setSeeMoreTurbineIds}
        getIconForTurbine={getIconForTurbine}
        renderManagedTurbines={renderManagedTurbines}
        toPrettyDateStr={toPrettyDateStr}
      />
    </div>
  );
}
