import React, { createContext, useState, useCallback } from "react";
import { defectAPI, documentAPI  } from "../api";
import { Buffer } from "buffer";


export const WdContext = createContext();

export default function WdProvider({ children }) {
  const [defectsLoading, setDefectsLoading] = useState(true);
  const [defects, setDefects] = useState([]);

  const [documents, setDocuments] = useState([]);
  const [documentsLoading, setDocumentsLoading] = useState(false);

  const getDefectsFlow = useCallback(async () => {
    setDefectsLoading(true);
    try {
      const result = await defectAPI.getDefects();
      setDefects(result);
    } catch (error) {
      console.error("Error fetching defects", error);
    } finally {
      setDefectsLoading(false);
    }
  }, []);

  const getWindfarmDefectsFlow = useCallback(async (windfarm_id) => {
    setDefectsLoading(true);
    try {
      const result = await defectAPI.getWindfarmDefects(windfarm_id);
      setDefects(result);
    } catch (error) {
      console.error("Error fetching windfarm defects", error);
    } finally {
      setDefectsLoading(false);
    }
  }, []);

  const getTurbineDefectsFlow = useCallback(async (turbine_id) => {
    setDefectsLoading(true);
    try {
      const result = await defectAPI.getTurbineDefects(turbine_id);
      setDefects(result);
    } catch (error) {
      console.error("Error fetching turbine defects", error);
    } finally {
      setDefectsLoading(false);
    }
  }, []);

  const setDefectRepairStatus = useCallback(async ({ defect_id, new_status }) => {
    const newDefectValues = { id: defect_id };

    try {
      switch (new_status) {
        case 'Not repaired':
          await defectAPI.markAsIgnore(defect_id);
          newDefectValues.markedForRepair = null;
          newDefectValues.repaired = null;
          break;
        case 'Marked for repair':
          await defectAPI.setMarkForRepair(defect_id);
          newDefectValues.repaired = null;
          newDefectValues.markedForRepair = 'yes';
          break;
        case 'Repaired':
          await defectAPI.setRepaired(defect_id);
          newDefectValues.repaired = 'yes';
          break;
        default:
          break;
      }

      setDefects((prevDefects) =>
        prevDefects.map((def) =>
          def.id === newDefectValues.id ? { ...def, ...newDefectValues } : def
        )
      );
    } catch (error) {
      console.error("Error updating defect status", error);
    }
  }, []);


  const uploadDocument = async ({ file_name, content, windfarm_id, turbine_id, callback }) => {
    try {
      setDocumentsLoading(true);
      const [heads, d] = content.split(";base64,");
      const binary_data = Buffer.from(d, "base64");
      const uploadedDocuments = await documentAPI.upload(file_name, binary_data, windfarm_id, turbine_id);
      setDocuments(uploadedDocuments);
      if (callback) callback();
    } catch (error) {
      console.error("Error uploading document:", error);
    } finally {
      setDocumentsLoading(false);
    }
  };

  const deleteDocument = async (document_id) => {
    try {
      setDocumentsLoading(true);
      await documentAPI.delete(document_id);
      setDocuments((prevDocuments) => prevDocuments.filter((doc) => doc.id !== document_id));
    } catch (error) {
      console.error("Error deleting document:", error);
    } finally {
      setDocumentsLoading(false);
    }
  };

  const getTurbineDocuments = async (turbine_id) => {
    try {
      setDocumentsLoading(true);
      const turbineDocuments = await documentAPI.getTurbineDocuments(turbine_id);
      setDocuments(turbineDocuments);
    } catch (error) {
      console.error("Error fetching turbine documents:", error);
    } finally {
      setDocumentsLoading(false);
    }
  };

  const getWindfarmDocuments = async (windfarm_id) => {
    try {
      setDocumentsLoading(true);
      const windfarmDocuments = await documentAPI.getWindfarmDocuments(windfarm_id);
      setDocuments(windfarmDocuments);
    } catch (error) {
      console.error("Error fetching windfarm documents:", error);
    } finally {
      setDocumentsLoading(false);
    }
  };


  return (
    <WdContext.Provider
      value={{
        defectsLoading,
        defects,
        getDefectsFlow,
        getWindfarmDefectsFlow,
        getTurbineDefectsFlow,
        setDefectRepairStatus,
        documents,
        documentsLoading,
        uploadDocument,
        deleteDocument,
        getTurbineDocuments,
        getWindfarmDocuments,
      }}
    >
      {children}
    </WdContext.Provider>
  );
}
