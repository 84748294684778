import { http } from "../api";

const easyUnpack = ({ data }) => data;
export const DataManagementApi = {
  parse_goose_report: {
    f: function parse_goose_report(report_id) {
      let url = '/data_management/parse_goose_report/$report_id';
      let parameterNames = ["$report_id"];
      [report_id].forEach((parameterValue, index) => {
        url = url.replace(parameterNames[index], parameterValue);
      });
      return http.get(url, { params: {} }).then(easyUnpack);
    },
  },
  parse_skyspecs: {
    f: function parse_skyspecs(bucket_path, turbine, inspection_date, file_data, report_id) {
      let url = '/data_management/goose/skyspecs/parse';
      return http.post(url, { bucket_path, turbine, inspection_date, file_data, report_id }).then(easyUnpack);
    },
  },
  parse_sulzer: {
    f: function parse_sulzer(bucket_path, report_id) {
      let url = '/data_management/goose/sulzer/parse';
      return http.post(url, { bucket_path, report_id }).then(easyUnpack);
    },
  },
  parse_drone_base: {
    f: function parse_drone_base(bucket_path, report_id) {
      let url = '/data_management/goose/drone-base/parse';
      return http.post(url, { bucket_path, report_id }).then(easyUnpack);
    },
  },
  list_turbines_from_skyspecs_file: {
    f: function list_turbines_from_skyspecs_file(file_data) {
      let url = '/data_management/goose/skyspecs/turbines';
      return http.post(url, { file_data }).then(easyUnpack);
    },
  },
  folder_structure: {
    f: function folder_structure({ bucket_path = null, wanted_file_types = null }) {
      let url = '/data_management/goose/folder-structure';
      return http.post(url, { bucket_path, wanted_file_types }).then(easyUnpack);
    },
  },
  parse_cornis: {
    f: function parse_cornis(bucket_path, json_name, blade_length) {
      let url = '/data_management/goose/cornis-import/parse';
      return http.post(url, { bucket_path, json_name, blade_length }).then(easyUnpack);
    },
  },
  base_view: {
    f: function base_view(parsed_object) {
      let url = '/data_management/goose/importable-object/commit';
      return http.post(url, { parsed_object }).then(easyUnpack);
    },
  },
  commit_importable_object: {
    f: function commit_importable_object(parsed_object) {
      let url = '/data_management/goose/cornis-import/commit';
      return http.post(url, { parsed_object }).then(easyUnpack);
    },
  },
  parse_pdg: {
    f: function parse_pdg(report_id) {
      let url = '/data_management/goose/pdg-import/parse/$bucket_path';
      return http.get(url, { params: { report_id } }).then(easyUnpack);
    },
  },
  parse_cr2_to_jpg: {
    f: function parse_cr2_to_jpg(file_name) {
      let url = '/data_management/goose/convert_cr2_to_jpg';
      return http.post(url, { file_name }).then(easyUnpack);
    },
  },
  parse_defect_excel: {
    f: function parse_defect_excel(file_data) {
      let url = '/data_management/self-service/parse_defect_excel';
      return http.post(url, { file_data }).then(easyUnpack);
    },
  },
  parse_inspection_pdf: {
    f: function parse_inspection_pdf(file_data, filename) {
      let url = '/data_management/self-service/parse_inspection_pdf';
      return http.post(url, { file_data, filename }).then(easyUnpack);
    },
  },
  parse_inspection_pdf_for_repair_recs: {
    f: function parse_inspection_pdf_for_repair_recs(filename, file_data) {
      let url = '/data_management/self-service/parse-inspection-pdf-rr';
      return http.post(url, { filename, file_data }).then(easyUnpack);
    },
  },
  create_xlsx_from_defects: {
    f: function create_xlsx_from_defects(parsedData) {
      let url = '/data_management/skalia/xlsx';
      return http.post(url, { parsedData }).then(easyUnpack);
    },
  },
  list_internal_videos: {
    f: function list_internal_videos(bucket_path) {
      let url = '/data_management/goose/internal_video/list_videos';
      return http.get(url, { params: { bucket_path } }).then(easyUnpack);
    },
  },
  encode_to_mp4: {
    f: function encode_to_mp4(movie_path) {
      let url = '/data_management/goose/internal_video/encode_mp4';
      return http.post(url, { movie_path }).then(easyUnpack);
    },
  },
  stabilize_video: {
    f: function stabilize_video(movie_path) {
      let url = '/data_management/goose/internal_video/stabilize';
      return http.post(url, { movie_path }).then(easyUnpack);
    },
  },
  get_overview: {
    f: function get_overview() {
      let url = '/data_management/goose/overview';
      return http.get(url, { params: {} }).then(easyUnpack);
    },
  },
  list_rocky_organizations: {
    f: function list_rocky_organizations() {
      let url = '/data_management/rocky/list/organizations';
      return http.get(url, { params: {} }).then(easyUnpack);
    },
  },
};
