import React, { useEffect, useMemo, useState, useContext } from "react";
import { LassieContext } from "../../../context/LassieProvider";
import { useParams } from "react-router-dom";
import { toPrettyTimeSince } from "../../../prettyDate";
import InfoCards from "../../../components/info-card/InfoCard";
import CenteredPopup from "../../../components/centered-popup/CenteredPopup";
import OlMap from "../../../components/ol-map/OlMap";
import TurbineAnimation from "../../../components/turbine-animation/TurbineAnimation";
import { useSyncSelectedWindfarm } from "../../../hooks/useSyncSelectedWindfarm ";
import TurbineManager from "../turbine-manager/TurbineManager";
import turbineManagerIcon from "../../../assets/img/turbine-manager-icon.png";
import turbineManagerIconColor from "../../../assets/img/turbine-manager-icon-color.png";
import "./lightning-windfarm-page.css";

export default function LightningWindfarmPage() {
  const [showTurbineManager, setShowTurbineManager] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { windfarms, wfStatistics, fetchStatistics, fetchTurbines, turbines } =
    useContext(LassieContext);

  let { windfarm_id } = useParams();
  if (windfarm_id) {
    windfarm_id = parseInt(windfarm_id);
  }

  const windfarm = useMemo(() => {
    if (!windfarms || !windfarm_id) return null;
    return windfarms.find((wf) => wf.id === windfarm_id);
  }, [windfarms, windfarm_id]);

  const hasData = wfStatistics.length > 0 && turbines.length > 0;

  useEffect(() => {
    if (!windfarm_id) return;

    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([fetchStatistics(windfarm_id), fetchTurbines(windfarm_id)]);
      setIsLoading(false);
    };

    fetchData();
  }, [windfarm_id]);

  useSyncSelectedWindfarm(windfarm_id);

  return (
    <div className="lightning-windfarm-page">
      <CenteredPopup
        className="turbine-manager turbine-list-popup"
        showPopup={showTurbineManager}
        closePopup={setShowTurbineManager}
      >
        <TurbineManager />
      </CenteredPopup>

      {isLoading ? (
        <TurbineAnimation />
      ) : hasData ? (
        <>
          <div className="statistics-container">
            <div>
              <h1 style={{ marginBottom: 0 }}>{windfarm && windfarm.name}</h1>
              {windfarm && (
                <p className="latest-data">
                  Latest data acquisition:{" "}
                  {toPrettyTimeSince(new Date(windfarm.latest_data))}
                </p>
              )}
            </div>

        <div className="lightning-activity-wrapper">
          <InfoCards statistics={wfStatistics} />
        </div>
        <div className="turbine-manager-wrapper">
          <button
            className="turbine-manager-btn"
            value="Turbine manager"
            onClick={() => setShowTurbineManager(true)}
          >
            Turbine Manager
            <img
              src={turbineManagerIcon}
              className="turbine-manager-icon default-icon"
              alt='Turbine icon'
            />
            <img
              src={turbineManagerIconColor}
              className="turbine-manager-icon hover-icon"
              alt='Turbine icon'
            />
          </button>
        </div>
      </div>

          <div className="map-wrapper">
            <OlMap
              key="main-map"
              lightning_strikes={[
                ...turbines.map((t) => t.turbine_lightning),
              ].flat()}
              turbines={turbines}
            />
          </div>
        </>
      ) : (
        <p>No data available for this windfarm.</p>
      )}
    </div>
  );
}
