import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function RouteTracker({ children }) {
    const location = useLocation();

    useEffect(() => {
        if (
            !location.pathname.startsWith("/logout") &&
            !location.pathname.startsWith("/login")
        ) {
            const fullPath = location.pathname + location.search;
            const currentStoredPath = localStorage.getItem("redirAfterLogin");

            if (currentStoredPath !== fullPath) {
                localStorage.setItem("redirAfterLogin", fullPath);
            }
        }
    }, [location]);

    return <>{children}</>;
}
