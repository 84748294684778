import React, {useCallback, useEffect, useState} from 'react';
import {lightningAPI, lightningSeverityAPI} from "../../../../api";
import WplTabs from "../../../../components/wpl-tabs/WplTabs";
import './lightning-severity-turbine.css'

export default function LightningSeverityTurbines() {
    const [windfarms, setWindfarms] = useState([]);
    const [selectedWindfarms, setSelectedWindfarms] = useState({});
    const [rulesets, setRulesets] = useState([]);

    const [turbines, setTurbines] = useState([]);

    useEffect(() => {
        lightningAPI.listLightningWindfarms().then(setWindfarms)
        lightningSeverityAPI.list_all_rulesets().then(setRulesets)
    }, []);

    const clickedWindfarm = useCallback(wf => {
        if (selectedWindfarms[wf.id]) {
            setSelectedWindfarms(prev => ({...prev, [wf.id]: false}))
            setTurbines(prev => prev.filter(t => t.windfarm_id !== wf.id))
            return;
        }
        setSelectedWindfarms(prev => ({...prev, [wf.id]: true}))
        lightningAPI.listTurbinesByWindfarmId(wf.id).then(turbs => {
            setTurbines(prev => [...prev, ...turbs.map(t => ({...t, windfarm: wf.name}))])
        })
    }, [selectedWindfarms]);

    return (<div className='lightning-severity-turbines'>
        <div className='windfarm-wrapper'>
            {windfarms.map(wf => <div key={wf.id} className={`wf ${selectedWindfarms[wf.id] ? 'selected' : ''}`} onClick={_ => clickedWindfarm(wf)}>{wf.name}</div>)}
        </div>
        <table>
            <thead>
            <tr>
                <th>Windfarm</th>
                <th>Turbine</th>
                <th>MW</th>
                <th>Hubheight</th>
                <th>Model</th>
                <th>Blade Length</th>
                <th>Approximate total height</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {turbines.map(turbine => <tr key={turbine.id}>
                <td>{turbine.windfarm}</td>
                <td>{turbine.name}</td>
                <td>{turbine.mw}</td>
                <td>{turbine.hub_height}</td>
                <td>{turbine.model}</td>
                <td>{turbine.blade_length}</td>
                <td>{turbine.blade_length + turbine.hub_height}</td>
                <td className='tab-td'>
                    <WplTabs setSelected={sel => {
                        const selectedRs = rulesets.find(rs => rs.name === sel)
                        lightningSeverityAPI.set_ruleset_for_turbine(turbine.id, selectedRs.id).then(() => {
                            setTurbines(prev => prev.map(t => t.id === turbine.id ? {...t, lightning_severity_ruleset_id: selectedRs.id} : t))
                        })
                    }}
                             tabTitles={rulesets.map(rs => rs.name)}
                             selected={turbine.lightning_severity_ruleset_id ? rulesets.find(rs => rs.id === turbine.lightning_severity_ruleset_id).name : ''}
                    />
                </td>
            </tr>)}
            </tbody>
        </table>
    </div>)
}
